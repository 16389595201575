<template>
  <main>
    <section class="container-fluid">
      <Navbar black active="documents" @localLoaded="setlocalLoaded" />
    </section>
    <div class="documents container mt-5" v-if="localLoaded">
      <div class="row">
        <div class="col">
          <h2>
            {{ $t('aboutDocuments.title') }}
          </h2>
        </div>
      </div>
      <div class="row mt-4 gx-5">
        <div class="col-sm-7">
          <h4 class="documents__title mb-4">
            {{ $t('aboutDocuments.subtitle') }}
          </h4>

          <div
            class="documents__content d-flex align-items-start mt-4"
            v-for="(doc, index) in $t('aboutDocuments').documents"
            :key="`doc-${index}`"
            @click="downloadDoc(doc)"
          >
            <div class="col-auto me-4">
              <span class="documents__icon">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13 7.5L12.295 6.795L8.5 10.585V1H7.5V10.585L3.705 6.795L3 7.5L8 12.5L13 7.5ZM13 12V14H3V12H2V14C2 14.5523 2.44772 15 3 15H13C13.5523 15 14 14.5523 14 14V12H13Z"
                    fill="#202020"
                  />
                </svg>

                <span class="documents__icon-ext"> {{ doc.value.ext }} </span>
              </span>
            </div>
            <div class="col">
              <p class="documents__paragraph">
                {{ doc.value.title }}
              </p>
              <!-- <span class="documents__icon-ext-size">(doc, 233 Кб)</span> -->
            </div>
          </div>
        </div>
        <div class="col-sm-5 mt-5 mt-sm-0">
          <img :src="getUrl + $t('aboutDocuments.image').path" class="img-fluid" alt="..." />
        </div>
      </div>
    </div>
    <Footer />
  </main>
</template>

<script>
import Navbar from '@/components/Navbar';
import Footer from '@/components/Footer';
import enviroment from '@/mixins/enviroment';

export default {
  mixins: [enviroment],
  metaInfo() {
    const title = this.$t('mainTitle');
    return {
      title,
      titleTemplate: '%s | atcbrok.kz'
    };
  },
  name: 'Documents',
  components: {
    Navbar,
    Footer
  },
  data() {
    return {
      localLoaded: false
    };
  },
  methods: {
    downloadDoc(doc) {
      const dataurl = this.getUrl + '/' + doc.value.file;
      const filename = doc.value.title;

      const a = document.createElement('a');
      a.href = dataurl;
      a.setAttribute('download', filename);
      a.click();
    },
    setlocalLoaded() {
      this.localLoaded = true;
    }
  }
};
</script>

<style scoped lang="scss">
.documents {
  &__paragraph {
    font-size: 1.25rem;
    margin: 0;
  }

  &__title {
    font-family: 'Fira Sans', sans-serif;
  }

  &__content {
    cursor: pointer;

    &:hover {
      transition: 0.3s;
      color: #528beb;

      svg path {
        transition: 0.3s;
        fill: #528beb;
      }
    }
  }

  &__icon {
    cursor: pointer;

    &-ext {
      font-size: 1.125rem;

      &-size {
        color: #979797;
        font-size: 1rem;
      }
    }
  }

  &__list {
    &-item {
      font-size: 1.25rem;
    }
  }
}
</style>
